//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.display-flex {
	display: flex;
	.form-check.form-switch {
		margin-right: 20px;
		margin-top: 10px;
	}
}

.center-spinner {
	position: absolute;
	top: 50%;
	right: 50%;
}

.flex-center {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.flex-center-row-nowrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.display-block {
	display: block;
}

.flex-center.float-end-flex-1 {
	flex: 1;
	justify-content: flex-end;
}

.flex-1 {
	flex: 1;
}

.card-form-group {
	padding: 0 5px;
}

.full-width {
	width: 100%;
}

.card-label.full-width {
	.card-title-wrapper {
		width: 100%;
	}
}

.truck-marker {
	background-image: url('../../assets/truck-marker.png');
	background-size: cover;
	width: 42px;
	height: 50px;
}

.map-search-and-legend {
	display: flex;
	align-items: center;
}

.route-map-legend {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-end;
	flex: 1;
	flex-wrap: wrap;
}

section.qr-scanner {
	width: 30%;
}

// Timeline styles
.main-route-timeline {
	font-family: 'Poppins', sans-serif;
	padding: 25px 10px;
}
.main-route-timeline:after {
	content: '';
	display: block;
	clear: both;
}
.main-route-timeline .route-timeline {
	width: calc(50% + 9px);
	padding: 20px 30px 20px 0;
	margin: -20px 0 0 0;
	border-radius: 20px;
	border: 10px solid transparent;
	float: left;
	position: relative;

	&.light {
		border-right: 10px solid #fff;
	}

	&.dark {
		border-right: 10px solid #242731;
	}

	.route-timeline-content {
		box-shadow: 1px 1px 29px 11px rgba(0, 0, 0, 0.75);
		-webkit-box-shadow: 1px 1px 29px 11px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 1px 1px 29px 11px rgba(0, 0, 0, 0.75);
	}
}

.main-route-timeline .route-timeline.info:before {
	background-color: #4d69fa;
}
.main-route-timeline .route-timeline.warning:before {
	background-color: #ffcf52;
}
.main-route-timeline .route-timeline.success:before {
	background-color: #46bcaa;
}
.main-route-timeline .route-timeline.danger:before {
	background-color: #f35421;
}

.main-route-timeline .route-timeline:after {
	background-color: #242731;
}

.main-route-timeline .route-timeline:before,
.main-route-timeline .route-timeline:after {
	content: '';
	height: 25px;
	width: 25px;
	border-radius: 50%;
	position: absolute;
	right: -18px;
	bottom: 50%;
	z-index: 1;
}
.main-route-timeline .route-timeline:after {
	border-radius: 3px;
	transform: rotate(45deg);
	right: 20px;
}

.main-route-timeline .route-timeline-content {
	color: #fff;
	display: block;
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 60%;
	border-radius: 2rem;

	.card {
		margin: 0;
		background-color: #242731;
		box-shadow: none;
		--bs-card-box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0);
		.card-header,
		.card-footer {
			background-color: #242731;
		}
	}
}

.main-route-timeline .route-timeline:nth-child(even) {
	.route-timeline-content {
		float: left;
	}
}

.main-route-timeline .route-timeline:nth-child(odd) {
	.route-timeline-content {
		float: right;
	}
}

.main-route-timeline .route-timeline:nth-child(even) {
	float: right;
	padding: 20px 0 20px 30px;
	margin: -20px 0 0 0;
	border-right: none;
	&.light {
		border-left: 10px solid #fff;
	}

	&.dark {
		border-left: 10px solid #242731;
	}
}
.main-route-timeline .route-timeline:nth-child(even):before {
	right: auto;
	left: -18px;
}
.main-route-timeline .route-timeline:nth-child(even):after {
	right: auto;
	left: 20px;
}
.main-route-timeline .route-timeline:nth-child(even) .route-timeline-icon {
	right: auto;
	left: 16px;
}

.search-action-field {
	background-color: rgba(9, 10, 12, 0.5);
	border-radius: 12px;
	padding: 2px 10px;
	width: 30%;
	max-height: 45px;
	flex: 2;
}

.search-action-field-noflex {
	background-color: rgba(9, 10, 12, 0.5);
	border-radius: 12px;
	padding: 2px 10px;
	width: 35%;
	max-height: 45px;
}

.grid-container {
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
	.grid-items {
		border: 1px solid;
	}
}
.dropArea {
	position: relative;
}
.dropArea::before {
	content: '';
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: #ebebeb;
}

@media screen and (max-width: 767px) {
	.main-route-timeline .route-timeline,
	.main-route-timeline .route-timeline:nth-child(even) {
		width: 100%;
		padding: 20px 0 20px 30px;
		margin: -25px 0 0;
		border-left: 10px solid #fff;
		border-right: none;
	}
	.main-route-timeline .route-timeline:before,
	.main-route-timeline .route-timeline:nth-child(even):before {
		right: auto;
		left: -17px;
	}
	.main-route-timeline .route-timeline:after,
	.main-route-timeline .route-timeline:nth-child(even):after {
		right: auto;
		left: 20px;
	}
	.main-route-timeline .description,
	.main-route-timeline .route-timeline:nth-child(even) .description {
		margin: 20px 20px 0 80px;
	}
	.main-route-timeline .route-timeline-icon,
	.main-route-timeline .route-timeline:nth-child(even) .route-timeline-icon {
		left: 16px;
		right: auto;
	}
}
@media screen and (max-width: 576px) {
	.main-route-timeline .route-timeline-content {
		padding-bottom: 70px;
	}
	.main-route-timeline .description,
	.main-route-timeline .route-timeline:nth-child(even) .description {
		margin: 20px 20px 0 20px;
	}
}
