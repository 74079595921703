//
// Board
//

.board {
	position: relative;
	height: 100%;
	flex-wrap: nowrap;
	overflow-x: scroll;
}

.board-group-container {
	// width: 18rem;
}

.board-group {
	width: 27rem;
}

.multiselect-dd .searchWrapper {
	height: $form-floating-height;
	line-height: $form-floating-line-height;
	border-radius: 12px;

	@include dark-mode {
		background-color: $dark-input-bg;
		color: $dark-input-color;

		&:not([class*='valid']) {
			border-color: $dark-input-border-color;
		}
	}

	.chip {
		background: $primary !important;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		@include dark-mode {
			background-color: $dark-input-disabled-bg;
		}
	}

	.searchBox {
		height: 30px;

		@include dark-mode {
			background-color: $dark-input-bg;
			color: $dark-input-color;
		}
	}
}

.multiselect-dd .optionListContainer {
	@include dark-mode {
		background-color: $dark-input-bg;
		color: $dark-input-color;

		&:not([class*='valid']) {
			border-color: $dark-input-border-color;
		}
	}

	.optionContainer {
		li.option:hover {
			background: $primary !important;
		}
		.highlightOption {
			background: $primary !important;
		}
	}
}

.form-select-sm {
	@include border-radius($form-select-border-radius-sm);
}

.form-select-lg {
	@include border-radius($form-select-border-radius-lg);
}
